export default {
  // 门状态
  // 1/开门;2/关门;3/常开;4/常闭;5/正常
  doorStatus(status) {
    let str = '';
    const statusMap = {
      1: {
        type: 'success',
        name: '开门'
      },
      2: {
        type: 'danger',
        name: '关门'
      },
      3: {
        type: 'warning',
        name: '常开'
      },
      4: {
        type: 'info',
        name: '常闭'
      },
      5: {
        type: '',
        name: '正常'
      }
    };
    str = statusMap[status];
    return str;
  },
  // 设备类型 ON ：在线 OFF:离线
  deviceStatus(status) {
    let str = '';
    const statusMap = {
      ON: {
        name: '在线'
      },
      OFF: {
        name: '离线'
      }
    };
    str = statusMap[status];
    return str;
  },
  // 读卡器类型
  // 1/进门;2/出门
  businessType(status) {
    let str = '';
    const statusMap = {
      1: {
        name: '进门'
      },
      2: {
        name: '出门'
      }
    };
    str = statusMap[status];
    return str;
  },
  //ACTIVE/激活;BLANK /空白;FROZEN/冻结;WITHDRAWN/注销
  cardAuthStatus(status) {
    let str = '';
    const statusMap = {
      "ACTIVE": {
        name: '激活',
        color: 'success'
      },
      "BLANK": {
        name: '空白',
        color: 'danger'
      },
      "FROZEN": {
        name: '冻结',
        color: 'warning'
      },
      "WITHDRAWN": {
        name: '注销',
        color: 'info'
      },
    };
    str = statusMap[status];
    return str;
  },
  authorizeStatus(status) {
    let str = '';
    const statusMap = {
      "1": {
        name: '未授权',
        color: 'danger',
        icon:'el-icon-circle-close'
      },
      "2": {
        name: '已授权',
        color: ' success',
        icon:'el-icon-circle-check'
      },
    };
    str = statusMap[status];
    return str;
  },
  taskStatus(status) {
    let str = '';
    const statusMap = {
      "0": {
        name: '已下发',
        color: 'success',
      },
      "1": {
        name: '未下发',
        color: 'danger',
      },
    };
    str = statusMap[status];
    return str;
  },
  detailTaskStatus(status) {
    let str = '';
    const statusMap = {
      "1": {
        name: '未下发',
        color: 'danger',
      },
      "2": {
        name: '已下发',
        color: 'success',
      },
    };
    str = statusMap[status];
    return str;
  },
  // 开门方式 openType
  openType(status) {
    let str = '';
    const statusMap = {
      1: {
        name: '仅密码开门'
      },
      2: {
        name: '仅刷卡开门'
      },
      3: {
        name: '密码或刷卡'
      },
      4: {
        name: '先刷卡后密码'
      },
      5: {
        name: '进门'
      },
      6: {
        name: '出门'
      },
      7: {
        name: '仅指纹开门'
      },
      8: {
        name: '密码或刷卡或指纹'
      },
      9: {
        name: '进门'
      },
      10: {
        name: '出门'
      },
      11: {
        name: '刷卡+指纹'
      },
      12: {
        name: '出门'
      },
      13: {
        name: '人证比对'
      },
      14: {
        name: '身份证+人证比对'
      },
      15: {
        name: '人证比对或刷卡或指纹'
      },
      16: {
        name: '(身份证+认证比对)或刷卡或指纹'
      },
      17: {
        name: '进门'
      },
      18: {
        name: '仅人脸识别开门'
      },
      19: {
        name: '人脸+密码'
      },
      20: {
        name: '指纹+密码'
      },
      21: {
        name: '指纹+人脸'
      },
      22: {
        name: '卡+人脸'
      },
      23: {
        name: '人脸或密码'
      },
      24: {
        name: '指纹或密码'
      },
      25: {
        name: '指纹或人脸'
      },
      26: {
        name: '卡或人脸'
      },
      27: {
        name: '卡或指纹'
      },
      28: {
        name: '指纹+人脸+密码'
      },
      29: {
        name: '卡+人脸+密码'
      },
      30: {
        name: '卡+指纹+密码'
      },
      31: {
        name: '卡+指纹+人脸'
      },
      32: {
        name: '指纹或人脸或密码'
      },
      33: {
        name: '卡或人脸或密码'
      },
      34: {
        name: '卡或指纹或人脸'
      },
      35: {
        name: '卡+指纹+人脸+密码'
      },
      36: {
        name: '卡或指纹或人脸或密码'
      },
      37: {
        name: '（身份证+人证比对）或刷卡或人脸'
      },
      38: {
        name: '人证比对 或 刷卡 或 人脸'
      }
    };
    str = statusMap[status];
    return str;
  }
};
